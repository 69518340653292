import React, {useState, useContext} from 'react';
import {useQuery} from 'react-query';
import {Box, Button, Stack, Typography, useTheme, useMediaQuery} from '@mui/material';
import {DataGridPro, GridToolbarContainer, GridToolbarQuickFilter} from '@mui/x-data-grid-pro';
import {MessageContext} from '../context/messageContext';
import orgAPI from '../apis/orgAPI';
import OrganizationForm from './OrganizationForm';
import {columns} from './organizationColumns';

export default function ManageOrganizations() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {messageState} = useContext(MessageContext);
  const [, setMessage] = messageState;

  const [selectedListItem, setSelectedListItem] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const defaultFormState = {
    code: '',
    name: '',
    abbrev: '',
    org_type_id: '',
    org_type_name: '',
    org_type_abbrev: '',
    description: '',
    active: true,
  };
  const [formState, setFormState] = useState(defaultFormState);

  const foundOrgs = useQuery(
    'fetchOrgs',
    () => orgAPI.getAll(),
    {
      onError: (error) => {setMessage({type: 'error', message: error.message})},
      staleTime: Infinity
    }
  );

  const handleCreateOrg = () => {
    setFormState(defaultFormState);
    setSelectedListItem(0);
    setSelectionModel([]);
    setShowEdit(true);
  };

  const handleRowSelect = (orgId) => {
    if (selectedListItem !== parseInt(orgId)) {
      setSelectedListItem(parseInt(orgId));
      setSelectionModel([orgId]);
      setShowEdit(true);
    }
    else {
      setSelectedListItem(0);
      setSelectionModel([]);
      setShowEdit(false);
    }
  };

  const handleSave = async () => {
    const foundOrg = foundOrgs.data?.find((obj) => obj.id === selectedListItem);
    
    const updateObj = {
      code: parseInt(formState.code),
      name: formState.name,
      abbrev: formState.abbrev,
      description: formState.description,
      active: formState.active ? true : false,
      org_type_id: formState.org_type_id
    };

    try {
      if (foundOrg?.id) {
        await orgAPI.update(`${selectedListItem}`, updateObj);
      } 
      else {
        await orgAPI.create(updateObj);
      }

      setFormState(defaultFormState); // reset form
      foundOrgs.refetch(); // reset query
      setMessage({type: 'success', message: 'Organization updated'})
    } 
    catch (err) {
      setMessage({type: 'error', message: err.response?.data?.message || err.toString()});
    }
  };

  const SearchToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarQuickFilter sx={{width: '100%'}}/>
    </GridToolbarContainer>
  );

  return (
    <Stack 
      direction={{xs: 'column-reverse', md: 'row'}} 
      spacing={{xs: 2, md: 4}} 
      sx={{px: {xs: 1, md: 0}, alignItems: 'center', justifyContent: 'center'}}
    >
      <Stack direction='column' spacing={1} sx={{alignSelf: 'start', alignItems: 'center', width: {xs: '100%', md: showEdit ? '50%' : '100%'}}}>
        <Stack
            direction='row'
            sx={{width: '100%', alignItems: 'center', justifyContent: 'end', my: '1rem'}}
        >
          <Button
              color='primary'
              variant='contained'
              size='large'
              onClick={handleCreateOrg}
          >
            Create
          </Button>
        </Stack>

        <Box sx={{width: '100%', display: 'flex', justifyContent: 'end'}}>
          <Typography variant='caption'>
            Select a row for more details
          </Typography>
        </Box>

        <Box sx={{height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 290px)', display: 'flex', width: '100%', pb: {xs: showEdit ? 6 : 0}}}>
          <DataGridPro
            sx={(theme) => ({
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: theme.palette.secondary.main,
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none'
              },
              '& .active-org': {
                color: theme.palette.primary.main
              },
              '& .inactive-org': {
                color: theme.palette.secondary.dark
              }
            })}
            slots={{
              toolbar: SearchToolbar
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'linear-progress',
                noRowsVariant: 'linear-progress'
              }
            }}
            columns={columns}
            rows={foundOrgs.data || []}
            loading={foundOrgs.isFetching}
            columnHeaderHeight={35}
            onRowClick={(params) => handleRowSelect(params.id)}
            disableMultipleRowSelection
            disableColumnMenu
            rowSelectionModel={selectionModel}
          />
        </Box>
      </Stack>

      <OrganizationForm
        organization={foundOrgs.data?.find((obj) => obj.id === selectedListItem)}
        handleSave={handleSave}
        formState={formState}
        setFormState={setFormState}
        defaultFormState={defaultFormState}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        selected={selectedListItem}
        setSelectionModel={setSelectionModel}
      />
    </Stack>
  );
}
