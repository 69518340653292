import {LocalizationProvider, DesktopDatePicker, MobileDatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {TextField} from '@mui/material';

export default function DatePickerWrapper({label, selectedDate, onChange, disabled, isDesktop, sx}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {
        isDesktop ?
          <DesktopDatePicker
            label={label}
            format='MM/DD/YYYY'
            value={selectedDate}
            disabled={disabled}
            onChange={onChange}
            renderInput={params => <TextField {...params} sx={...sx}/>}
          /> :
          <MobileDatePicker
            label={label}
            format='MM/DD/YYYY'
            value={selectedDate}
            disabled={disabled}
            onChange={onChange}
            renderInput={params => <TextField {...params} sx={...sx}/>}
          />
      }
    </LocalizationProvider>
  );
}
