import {useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select,
  Stack, TextField} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import orgAPI from '../apis/orgAPI';

export default function OrganizationForm({organization, handleSave, formState, setFormState,
                                           defaultFormState, setShowEdit, showEdit, selected,
                                           setSelectionModel}) {
  const [codeError, setCodeError] = useState(false);

  const foundOrgTypes = useQuery(
    'fetchOrgTypes',
    () => orgAPI.getOrgTypes(),
    {staleTime: Infinity}
  );

  const orgTypesList = useMemo(
    () => foundOrgTypes.isSuccess ? foundOrgTypes.data : [],
    [foundOrgTypes]
  );

  const handleInputChange = (event) => {
    const {target: {name, value}} = event;

    if (name === 'code') {
      if (!value.match(/^\d*$/))
        setCodeError(true);
      else
        setCodeError(false);
    }

    setFormState({...formState, [name]: value});
  };

  const handleSelectChange = (event) => {
    const {target: {name, value}} = event;
    const foundOrgType = orgTypesList.find((type) => type.abbrev === value)
    setFormState({...formState, [name]: value, org_type_id: foundOrgType?.id, org_type_name: foundOrgType?.name});
  }

  const handleCheckboxChange = (event) => {
    const {target: {name, checked}} = event;
    setFormState({...formState, [name]: checked});
  }

  const handleClose = () => {
    setSelectionModel([]);
    setShowEdit(false);
  };

  useEffect(() => {
    if (organization) {
      setFormState({
        code: organization?.code,
        name: organization?.name,
        abbrev: organization?.abbrev ?? '',
        org_type_id: organization?.org_type_id,
        org_type_name: organization?.org_type_name,
        org_type_abbrev: organization?.org_type_abbrev,
        description: organization?.description ?? '',
        active: organization?.active,
      });
    }
    else {
      setFormState(defaultFormState);
    }
  }, [organization])

  return (
    <>
      {showEdit &&
        <Stack direction='column' spacing={2} sx={{width: '100%', maxWidth: 400, mx: 'auto'}}>
          <Stack direction='row' spacing={2} sx={{width: '100%', justifyContent: 'flex-end'}}>
            <IconButton onClick={handleClose}>
              <CloseIcon/>
            </IconButton>
          </Stack>

          <FormControlLabel
            control={
              <Checkbox
                name='active'
                checked={formState.active ? true : false}
                onChange={handleCheckboxChange}
              />
            }
            label='Active'
            sx={{width: 100}}
          />

          <TextField
            required
            id='herd-code-input'
            name='code'
            label='Code'
            value={formState.code}
            onChange={handleInputChange}
            fullWidth
            error={codeError}
            helperText={selected === 0 && 'Code must have numbers only'}
            disabled={selected !== 0}
          />

          <TextField
            required
            id='herd-name-input'
            name='name'
            label='Name'
            value={formState.name}
            onChange={handleInputChange}
            fullWidth
          />

          <TextField
            id='herd-abbreviation-input'
            name='abbrev'
            label='Abbreviation'
            value={formState.abbrev}
            onChange={handleInputChange}
            fullWidth
          />

          <TextField
            id='herd-description-input'
            name='description'
            label='Description'
            value={formState.description}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={2}
          />

          <FormControl required sx={{width: 100}}>
            <InputLabel id='herd-type-label'>Type</InputLabel>
            <Select
              labelId='herd-type-label'
              id='herd-type'
              name='org_type_abbrev'
              label='Type'
              value={formState.org_type_abbrev}
              onChange={handleSelectChange}
            >
              <MenuItem value=''>No Selection</MenuItem>
              {orgTypesList.map((option) => (
                <MenuItem key={option.id} value={option.abbrev}>{option.abbrev}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack direction='row' spacing={2} sx={{width: '100%', justifyContent: 'flex-end'}}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleSave}
              disabled={!(formState.code && formState.name && formState.org_type_id) || codeError}
            >
              {organization?.name ? 'Update' : 'Submit'}
            </Button>
          </Stack>
        </Stack>}
    </>
  );
}
