import {
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MessageContext } from "../../context/messageContext";
import AgreementConsent from "./AgreementConsent";
import AgreementLevel from "./AgreementLevel";
import {agreementsHelpText} from '../agreementsHelpText';
import herdAPI from "../../apis/herdAPI";
import UsernameDateTime from "./UserDateTimeBox";
import { useParams } from "react-router-dom";
import {jsonDiff} from '../../util';

const parseCodes = (rawCodes) => (
  rawCodes ? JSON.parse(rawCodes) : []
);

export default function AgreementsPanel({ id, herd, herdRefetch, showButton = true, usePdfLayout = false }) {
  const { id: herdCode } = useParams();
  const { messageState } = useContext(MessageContext);
  const queryClient = useQueryClient();

  const [, setMessage] = messageState;

  const [dataConsent, setDataConsent] = useState(herd.data_publication_consent);
  const [piiConsent, setPiiConsent] = useState(herd.pii_consent);
  const [isReadOnly, setIsReadOnly] = useState(herd.data_publication_consent ? false: true);

  const [healthLevel, setHealthLevel] = useState(herd.health_level);
  const [productionLevel, setProductionLevel] = useState(herd.production_level);
  const [reproductionLevel, setReproductionLevel] = useState(herd.reproduction_level);
  const [managementLevel, setManagementLevel] = useState(herd.management_level);
  const [sensorLevel, setSensorLevel] = useState(herd.sensor_level);
  const [nutritionLevel, setNutritionLevel] = useState(herd.nutrition_level);
  const [otherLevel, setOtherLevel] = useState(herd.other_level);

  const [healthCodes, setHealthCodes] = useState(parseCodes(herd.health_org_codes));
  const [productionCodes, setProductionCodes] = useState(parseCodes(herd.production_org_codes));
  const [reproductionCodes, setReproductionCodes] = useState(parseCodes(herd.reproduction_org_codes));
  const [managementCodes, setManagementCodes] = useState(parseCodes(herd.management_org_codes));
  const [sensorCodes, setSensorCodes] = useState(parseCodes(herd.sensor_org_codes));
  const [nutritionCodes, setNutritionCodes] = useState(parseCodes(herd.nutrition_org_codes));
  const [otherCodes, setOtherCodes] = useState(parseCodes(herd.other_org_codes));

  useEffect(() => {
    setDataConsent(herd.data_publication_consent);
    setPiiConsent(herd.pii_consent);

    setHealthLevel(herd.health_level);
    setProductionLevel(herd.production_level);
    setReproductionLevel(herd.reproduction_level);
    setManagementLevel(herd.management_level);
    setSensorLevel(herd.sensor_level);
    setNutritionLevel(herd.nutrition_level);
    setOtherLevel(herd.other_level);

    setHealthCodes(parseCodes(herd.health_org_codes));
    setProductionCodes(parseCodes(herd.production_org_codes));
    setReproductionCodes(parseCodes(herd.reproduction_org_codes));
    setManagementCodes(parseCodes(herd.management_org_codes));
    setSensorCodes(parseCodes(herd.sensor_org_codes));
    setNutritionCodes(parseCodes(herd.nutrition_org_codes));
    setOtherCodes(parseCodes(herd.other_org_codes));
  }, [herd]);

  const consentArray = [
    {
      label: "Personal Identifying Information",
      name: "pii_consent",
      consent: piiConsent,
    },
    {
      label: 'Data Disclosure & Publication',
      name: "data_publication_consent",
      consent: dataConsent,
    }
  ];

  const levelArray = [
    { label: "Health", name: "health_level", level: healthLevel, codes: healthCodes, helpText: agreementsHelpText.health },
    { label: "Production", name: "production_level", level: productionLevel, codes: productionCodes, helpText: agreementsHelpText.production },
    { label: "Reproduction", name: "reproduction_level", level: reproductionLevel, codes: reproductionCodes, helpText: agreementsHelpText.reproduction },
    { label: "Management", name: "management_level", level: managementLevel, codes: managementCodes, helpText: agreementsHelpText.management },
    { label: "Sensor", name: "sensor_level", level: sensorLevel, codes: sensorCodes, helpText: agreementsHelpText.sensor },
    { label: "Nutrition", name: "nutrition_level", level: nutritionLevel, codes: nutritionCodes, helpText: agreementsHelpText.nutrition },
    { label: "Other", name: "other_level", level: otherLevel, codes: otherCodes, helpText: agreementsHelpText.other },
  ];

  const levelChange = (event) => {
    const {
      target: { value, name },
    } = event;
    const valInt = parseInt(value);

    switch (name) {
      case "health_level":
        setHealthLevel(valInt);
        if (valInt !== 3)
          setHealthCodes([]);
        break;
      case "production_level":
        setProductionLevel(valInt);
        if (valInt !== 3)
          setProductionCodes([]);
        break;
      case "reproduction_level":
        setReproductionLevel(valInt);
        if (valInt !== 3)
          setReproductionCodes([]);
        break;
      case "management_level":
        setManagementLevel(valInt);
        if (valInt !== 3)
          setManagementCodes([]);
        break;
      case "sensor_level":
        setSensorLevel(valInt);
        if (valInt !== 3)
          setSensorCodes([]);
        break;
      case "nutrition_level":
        setNutritionLevel(valInt);
        if (valInt !== 3)
          setNutritionCodes([]);
        break;
      case "other_level":
        setOtherLevel(valInt)
        if (valInt !== 3)
          setOtherCodes([]);
        break;
      default:
        console.info(`level=${name} is not valid with value=${valInt}`);
    }
  };

  const codeChange = (event) => {
    const {
      target: {value, name}
    } = event;

    // On autofill we get a stringified value.
    const val = typeof value === 'string' ? value.split(',') : value

    switch (name) {
      case "health_level":
        setHealthCodes(val);
        break;
      case "production_level":
        setProductionCodes(val);
        break;
      case "reproduction_level":
        setReproductionCodes(val);
        break;
      case "management_level":
        setManagementCodes(val);
        break;
      case "sensor_level":
        setSensorCodes(val);
        break;
      case "nutrition_level":
        setNutritionCodes(val);
        break;
      case "other_level":
        setOtherCodes(val)
        break;
    }
  };

  const consentChange = (event) => {
    const {
      target: { value, name },
    } = event;
    const valInt = parseInt(value);
    const YES = 1;

    switch (name) {
      case "data_publication_consent":
        setDataConsent(valInt);
        const val = (valInt) === 0 ? 0 : 3;

        if (valInt === YES) {  // data consent is YES
          setIsReadOnly(false);
        } else {
          setIsReadOnly(true);
        }

        setHealthLevel(val);
        setProductionLevel(val);
        setReproductionLevel(val);
        setManagementLevel(val);
        setSensorLevel(val);
        setNutritionLevel(val);
        setOtherLevel(val);
        break;
      case "pii_consent":
        setPiiConsent(valInt);
        break;
    }
  };

  const calculateAgreementDiff = () => {
    const originalData = {};
    const updatedData = {};

    for (let rec of consentArray) {
      originalData[rec.name] = herd[rec.name];
      updatedData[rec.name] = rec.consent;
    }

    for (let rec of levelArray) {
      originalData[rec.name] = herd[rec.name];
      updatedData[rec.name] = rec.level;

      const codesLabel = `${rec.label.toLowerCase()}_org_codes`;
      originalData[codesLabel] = parseCodes(herd[codesLabel]);
      updatedData[codesLabel] = rec.codes;
    }

    const diff = jsonDiff(originalData, updatedData);

    return Object.keys(diff).length ? diff : null;
  };

  const handleSaveAgreements = useMutation(
    () => {
      const updateObj = {};

      for (let rec of consentArray) {
        updateObj[rec.name] = Boolean(rec.consent);
      }

      for (let rec of levelArray) {
        const category = rec.label.toLowerCase();
        updateObj[category] = {};
        updateObj[category].consent_level = rec.level;
        updateObj[category].receiving_org_codes = rec.level === 3 ? rec.codes : []
      }

      return herdAPI.update(herd.code, {
        agreement: updateObj,
      });
    },
    {
      onMutate: async () => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(["getOneHerd", herdCode]);

        // Snapshot the previous value
        const previousHerdData = queryClient.getQueryData([
          "getOneHerd",
          herdCode,
        ]);

        // Optimistically update to the new values
        queryClient.setQueryData(["getOneHerd", herdCode], {
          ...previousHerdData,
          dataConsent,
          piiConsent,
          healthLevel,
          productionLevel,
          reproductionLevel,
          managementLevel,
          sensorLevel,
          nutritionLevel,
          otherLevel,
        });

        // Return a context object with the snapshotted value
        return { previousHerdData };
      },
      onSettled: herdRefetch, // Always refetch after error or success
      onSuccess: async () => {
        setMessage({
          type: "success",
          message: "Agreement preferences updated.",
        });
        await queryClient.invalidateQueries(['getHistoryResults', herdCode]);
      },
      onError: (error, newHerdData, context) => {
        setMessage({ type: "error", message: "Could not update agreements." });

        // Reset the query data to the previous herd data
        queryClient.setQueryData(
          ["getOneHerd", herdCode],
          context?.previousHerdData || {}
        );
      },
    }
  );

  return (
    <Stack id={id} direction="column" spacing={usePdfLayout ? 2 : 4} sx={{mb: 2}}>
      <Typography variant="h6" sx={{textAlign: 'center'}}>
        Data Access and Use Agreement
      </Typography>
      <AgreementsTable rows={accessUseAgreementLevels} usePdfLayout={usePdfLayout} />
      <Stack
        direction="column"
        spacing={usePdfLayout ? 2 : {xs: 1, sm: 2, md: 4}}
        sx={{justifyContent: 'flex-start', alignItems: 'stretch'}}
      >
        {consentArray.map((c) => (
          <AgreementConsent
            key={c.name}
            name={c.name}
            label={c.label}
            value={c.consent}
            handleChange={consentChange}
            usePdfLayout={usePdfLayout}
          />
        ))}
        {levelArray.map((ag) => (
          <AgreementLevel
            key={ag.name}
            name={ag.name}
            label={ag.label}
            level={ag.level}
            codes={ag.codes}
            helpText={ag.helpText}
            handleLevelChange={levelChange}
            handleCodeChange={codeChange}
            readOnly={isReadOnly}
            usePdfLayout={usePdfLayout}
          />
        ))}
      </Stack>
      <Stack direction="row" spacing={2} sx={{justifyContent: 'space-between'}}>
        <UsernameDateTime
          username={herd.agreement_updated_by}
          org={herd.agreement_updated_by_organization}
          dateTime={herd.agreement_updated_datetime}
        />
        {
          showButton && !handleSaveAgreements.isLoading ?
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSaveAgreements.mutate()}
              disabled={!calculateAgreementDiff()}
            >
              Save
            </Button> :
            <CircularProgress />
        }
      </Stack>
    </Stack>
  );
}

/**
 * CHILDREN COMPONENTS
 */
function AgreementsTable({ rows, usePdfLayout }) {
  const { palette, breakpoints } = useTheme();
  const isMobile = usePdfLayout ? false : useMediaQuery(breakpoints.down("sm"));

  const [openDialog, setOpenDialog] = useState(null);

  const handleDialogOpen = (event) => {
    const {
      target: { id },
    } = event;
    setOpenDialog(id);
  };
  const handleDialogClose = () => {
    setOpenDialog(null);
  };

  return (
    <TableContainer component={Paper} sx={{boxShadow: 'unset'}}>
      <Table aria-label="agreement table">
        <TableHead>
          <TableRow sx={{ backgroundColor: palette.secondary.main }}>
            <TableCell sx={{ px: 1 }}></TableCell>
            <TableCell align="center" sx={{ px: 1 }}>
              CODE
            </TableCell>
            <TableCell align="center" sx={{ px: 1 }}>
              PERMITTED DISCLOSURE
            </TableCell>
            <TableCell align="center" sx={{ px: 1 }}>
              PERMITTED USE
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow
              key={row.level}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell align="center" sx={{ p: 0 }}></TableCell>
              <TableCell align="center" sx={{ p: 0 }}>
                {row.level}
              </TableCell>
              <TableCell
                align={isMobile ? "center" : "left"}
                sx={{ verticalAlign: "text-top", px: 1 }}
              >
                {isMobile ? (
                  <TextDialog
                    open={openDialog === `View Disclosure-${row.level}`}
                    handleClickOpen={handleDialogOpen}
                    handleClose={handleDialogClose}
                    label="View Disclosure"
                    level={row.level}
                    fullText={row.disclosure}
                  />
                ) : (
                  row.disclosure
                )}
              </TableCell>
              <TableCell align="left" sx={{ verticalAlign: "text-top", px: 1 }}>
                {isMobile ? (
                  <TextDialog
                    open={openDialog === `View Use-${row.level}`}
                    handleClickOpen={handleDialogOpen}
                    handleClose={handleDialogClose}
                    label="View Use"
                    level={row.level}
                    fullText={row.use}
                  />
                ) : (
                  row.use
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell align="left" colSpan={3} sx={{ verticalAlign: "text-top", px:1, paddingLeft: "38px" }}>
              <a href="https://dhia.org/wp-content/uploads/2021/08/Uniform-Operating-Procedures-Effective-01262021.pdf" target="_blank" rel="noreferrer">
                Uniform Operating Procedures Effective 01-26-2021
              </a>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function TextDialog({
  open,
  handleClickOpen,
  handleClose,
  label,
  level,
  fullText,
}) {
  return (
    <>
      <Link id={`${label}-${level}`} onClick={handleClickOpen}>
        {label}
      </Link>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {label} {level}
        </DialogTitle>
        <Typography variant="body1" sx={{ px: 3, pb: 3 }}>
          {fullText}
        </Typography>
      </Dialog>
    </>
  );
}

/**
 * CONSTANTS
 */
const accessUseAgreementLevels = [
  {
    level: 1,
    disclosure:
      "Data remains within the DHI system only and does not go beyond DRPC",
    use: "Data can be used by Field Service Affiliates, Laboratories, and DRPCs to create new management tools and benchmarks.",
  },
  {
    level: 2,
    disclosure:
      "Data flows from the DRPC to CDCB and AGIL, but does not go beyond CDCB or AGIL",
    use: "Research, management benchmarks, and the calculation and distribution of genetic and genomic evaluations",
  },
  {
    level: 3,
    disclosure:
      "Data flows from the DRPC to CDCB and AGIL, and may then flow to any authorized recipient",
    use: "The specific purpose identified in the third-party license agreement entered between National DHIA and the authorized recipient",
  },
];
