import {FC, useState} from "react";
import {Box, Chip, TextField, Typography} from "@mui/material";
import {Simulate} from "react-dom/test-utils";

type ChipMultiInputProps = {
  values: any[],
  onAdd: (value: string) => void
  onRemove: (value: string) => void
  inputRegex?: RegExp
  invalidFormatMessage?: string,
  containerSx: any
}

const ChipMultiInput: FC<ChipMultiInputProps> = ({values, onAdd, onRemove, inputRegex, invalidFormatMessage, containerSx}) => {
  const [textInput, setTextInput] = useState('');
  const [inputError, setInputError] = useState('');

  function handleKeyDown(event) {
    if (event.key === "Enter" || event.key === ',') {
      event.preventDefault();

      const duplicatedValues = values.indexOf(
        event.target.value.trim()
      );

      if (duplicatedValues === -1 && event.target.value.replace(/\s/g, "").length) {
        if (!inputRegex || inputRegex.test(textInput)) {
          onAdd(textInput);
          setTextInput('');
          setInputError('');
        } else {
          setInputError(invalidFormatMessage || 'Invalid format');
        }
      }

    }
    else if (values.length && !textInput.length && event.key === "Backspace") {
      onRemove(textInput);
    }
  }

  function handleChange(event) {
    const item = event.target.value;
    setTextInput(item);
  }

  const handleDelete = (chip) => {
    onRemove(chip);
  }

  return (
    <>
      <TextField
        value={textInput}
        placeholder='Add an email and press Enter or comma (,)'
        sx={(theme) => ({ flexDirection: "column", ...containerSx})}
        slotProps={{
          input: {
            sx: { display: "flex", flexDirection: "column" },
            startAdornment: (
              <Box sx={{width: '100%', m: 1}}>
                {values.map((item) => (
                  <Chip
                    key={item}
                    tabIndex={-1}
                    label={item}
                    onDelete={() => handleDelete(item)}
                    sx={{mx: '.1rem', mb: '.2rem'}}
                  />
                ))}
              </Box>
            ),
            onChange: handleChange,
            onKeyDown: handleKeyDown
          }
        }}
      />
      <Typography variant='body2' sx={{color: 'red'}}>{inputError}</Typography>
    </>
  );
};

export default ChipMultiInput;