import {useState} from 'react';
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Stack, TextField,
  Typography,
} from "@mui/material";
import {IconButton} from '@mui/material';
import {Help, HelpOutline} from '@mui/icons-material';
import {useResponsive} from "../../hooks/style";
import {useQueryClient} from "react-query";

export default function AgreementLevel({
  name,
  label,
  level,
  codes,
  helpText,
  handleLevelChange = () => {},
  handleCodeChange = () => {},
  readOnly = false,
  usePdfLayout
}) {
  const {isDesktop, isPhone} = useResponsive();

  const [anchorEl, setAnchorEl] = useState(null);

  const borderStyle = "1px solid #cfcfcf";

  const queryClient = useQueryClient();

  const orgs = queryClient.getQueryData('allOrgs');

  const handleHelpClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setAnchorEl(null);
  };

  const helpOpen = Boolean(anchorEl);

  return (
    <Paper sx={{ p: 2, pr: isPhone && !usePdfLayout ? 0 : 2, border: borderStyle, boxShadow: 'unset' }}>
      <Stack
        direction="row"
        spacing={usePdfLayout ? 4 : {xs: 0, sm: 3, md: 4}}
        sx={{alignItems: 'center'}}
      >
        <Box
          sx={{
            marginLeft: '50px',
            width: isPhone && !usePdfLayout ? '40%' : {sm: '30%', md: '50%'},
            display: !isPhone ? 'flex' : 'block',
            alignItems: 'center'
          }}
        >
          <Typography variant="body1">
            {label}
          </Typography>
          <IconButton color="primary" onClick={handleHelpClick}>
            <Help/>
          </IconButton>
          <Popover
            open={helpOpen}
            anchorEl={anchorEl}
            onClose={handleHelpClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{maxWidth: {xs: '96%', md: '90%'}}}  // See src/layouts/AppLayout.tsx, Box component
          >
            <Typography sx={{p: 2}}>{helpText}</Typography>
          </Popover>
        </Box>
        <Stack
          direction={usePdfLayout ? 'row' : {xs: 'column', sm: 'row'}}
          spacing={usePdfLayout ? 3 : {xs: 0, sm: 2, md: 3}}
          sx={{alignItems: 'center'}}
        >
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Code</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name={name}
              value={level}
              onChange={readOnly ? () => {} : handleLevelChange}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="1"
                disabled={readOnly}
                sx={isPhone && !usePdfLayout ? {mr: 1} : {}}
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="2"
                disabled={readOnly}
                sx={isPhone && !usePdfLayout ? {mr: 1} : {}}
              />
              <FormControlLabel
                value={3}
                control={<Radio />}
                label="3"
                disabled={readOnly}
                sx={isPhone && !usePdfLayout ? {mr: 1} : {}}
              />
            </RadioGroup>
          </FormControl>
          {
            !usePdfLayout ?
              <FormControl>
                <InputLabel id='ddo-label'>DDOs</InputLabel>
                <Select
                  labelId='ddo-label'
                  id='ddo-select'
                  disabled={level !== 3}
                  multiple
                  name={name}
                  value={codes}
                  onChange={handleCodeChange}
                  input={<OutlinedInput label='DDOs'/>}
                  renderValue={selected => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                      {selected.map(value => (
                        <Chip key={value} label={value}/>
                      ))}
                    </Box>
                  )}
                  sx={{width: 150}}
                >
                  {orgs?.filter(org => org.org_type_abbrev === 'DDO').map(org => (
                    <MenuItem
                      key={org.code}
                      value={org.code}
                    >
                      {`${org.code} - ${org.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              :
              <TextField
                name="ddos_pdf"
                label="DDOs"
                value={codes.join(', ')}
                sx={{width: 150}}
              />
          }
        </Stack>
      </Stack>
    </Paper>
  );
}
