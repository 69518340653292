import { UserDateTimeProps } from "../../herdportalTypes";
import { formatDate } from '../../util/dateUtil';
import {Box} from "@mui/material";

export default function HerdInformationForm({ username, org, dateTime }: UserDateTimeProps) {
    if (!org) {
        org = 'system';
    }

    if (username === 'db-migrator') {
        username = 'initial data load';
    }

    return (
        <>
            {username && dateTime ?
              <Box sx={{color: 'gray', fontFamily: 'sans-serif', fontSize: '12px', justifyContent: 'flex-start'}}>Updated by {username}: {org} at {formatDate(dateTime)}</Box> :
              <Box sx={{color: 'gray', fontFamily: 'sans-serif', fontSize: '12px', justifyContent: 'flex-start'}}>Record not updated</Box>
            }
        </>
    );
}