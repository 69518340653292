const defaultWidth = 310;

export const historyColumns = [
  {
    field: 'created_datetime',
    headerName: 'Datetime (UTC)',
    valueFormatter: (value) => new Date(value).toLocaleString(),
    minWidth: 200
  },
  {
    field: 'created_by',
    headerName: 'Updated By',
    minWidth: defaultWidth,
  },
  {
    field: 'fields_changed',
    headerName: 'Fields Changed',
    minWidth: 550,
  }
];