import { StrictMode } from "react";
import axios from "axios";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Amplify } from "aws-amplify";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import type {} from '@mui/x-data-grid/themeAugmentation';
import { LicenseInfo } from "@mui/x-license";

import Auth from "./auth";
import App from "./App";
import { getDataGridLicenseKey } from './util';
import { SessionProvider } from "./context/sessionContext";
import { MessageProvider } from './context/messageContext';
import { SearchProvider } from './context/SearchContext';

// @ts-ignore
Amplify.configure(window.amplifyConfig);
declare module '@mui/material/styles' {
  // allow configuration using `createTheme`
  interface ThemeOptions {
    pallete?: {
      primary?: {
        main?: string
      },
      secondary?: {
        main?: string
      }
    };
  }
}

// @ts-ignore
const appTheme = responsiveFontSizes(createTheme({
  palette: {
    primary: {main: '#01674F', light: '#e0f2ee'},
    secondary: {main: '#DFD7D8', dark: '#8F8C8C'}
  },
  mixins: {
    MuiDataGrid: {
      containerBackground: '#DFD7D8',
      pinnedBackground: '#FFF'
    }
  }
}));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
    },
  },
});

axios.interceptors.request.use(
  async (request) => {
    const token = await Auth.getIdToken();
    if (token) { // @ts-ignore
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    console.error('API error', error);
  },
);

LicenseInfo.setLicenseKey(getDataGridLicenseKey());

const showDevTools = true; // Flip this boolean if you want to hide the dev tools

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <StrictMode>
      <SessionProvider>
      <MessageProvider>
      <SearchProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={appTheme}>
            <App />
            {showDevTools && (process.env.DOMAIN === undefined || process.env.DOMAIN === '') ? (
              <ReactQueryDevtools initialIsOpen={false} />
            ) : null}
          </ThemeProvider>
        </QueryClientProvider>
      </SearchProvider>
      </MessageProvider>
      </SessionProvider>
    </StrictMode>
  </BrowserRouter>
  , rootElement);