import React, { FC, useState } from "react";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAlert from "../hooks/alert/useAlert";
import Auth from "../auth";
import ConfirmPasswordReset from "./ConfirmPasswordReset";
import {useResponsive} from "../hooks/style";

const ForgotPassword: FC = () => {
  const alert = useAlert();
  const [typedUsername, setTypedUsername] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const {isDesktop, isPhone} = useResponsive();

  const handleUsernameSubmit = (event) => {
    Auth.forgotPassword(typedUsername)
      .then(() => {
        setUsername(typedUsername);
      })
      .catch((error) => {
        alert.error(error.message);
        setUsername("");
      });
  };

  return username ? (
    <ConfirmPasswordReset username={username} />
  ) : (
    <Paper sx={{ px: isPhone ? 2 : 0, py: 6, maxWidth: "sm", mx: isPhone ? 2 : "auto" }}>
      <Stack
        direction="column"
        spacing={4}
        sx={{mx: 'auto', maxWidth: '300px', alignItems: 'center'}}
      >
        <Typography variant="h4">Forgot Password</Typography>

        <TextField
          variant="outlined"
          fullWidth
          type="username"
          label="Username"
          value={typedUsername}
          onChange={(e) => setTypedUsername(e.target.value)}
        />

        <Stack direction="row" sx={{justifyContent: 'flex-end', width: '100%'}}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/login")}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleUsernameSubmit}
            sx={{ ml: 1 }}
          >
            Send Code
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ForgotPassword;
