export const columns = [
  {
    field: 'code',
    headerName: 'Code',
    minWidth: 100
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 300
  },
  {
    field: 'org_type_abbrev',
    headerName: 'Org Type',
    valueGetter: (value) => value === 'FSP' ? 'FSA' : value,
    minWidth: 110
  },
  {
    field: 'active',
    headerName: 'Status',
    valueGetter: (value) => value ? 'Active' : 'Inactive',
    cellClassName: (params) => params.value === 'Active' ? 'active-org' : 'inactive-org',
    minWidth: 100
  }
];
