import {Actions, OrgSelect, AdminCheckbox} from './userComponents';

export const getSuColumns = (sessionUsername, userState, setUserState, handleRowChange, handleApiError, isMobile) => {
  return [
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 125,
      renderCell: params =>
        <Actions
          userId={params.row.user_id}
          userState={userState}
          isActive={params.row.active}
          handleApiError={handleApiError}
        />
    },
    {
      field: 'cognito_username',
      headerName: 'Username',
      minWidth: 200,
      valueFormatter: (value) =>
        value === sessionUsername ? `${value} (you)` : value
    },
    {
      field: 'active',
      headerName: 'Status',
      valueFormatter: (value) => value ? 'Active' : 'Inactive',
      cellClassName: (params) => params.value ? 'active-user' : 'inactive-user',
      minWidth: 115
    },
    {
      field: 'org_name',
      headerName: 'Organization',
      minWidth: isMobile ? 230 : 300,
      renderCell: params =>
        <OrgSelect
          userId={params.row.user_id}
          userState={userState}
          setUserState={setUserState}
          handleRowChange={handleRowChange}
        />
    },
    // TODO add account_created after updating person API with field
    {
      field: 'is_admin',
      headerName: 'Admin',
      minWidth: 115,
      renderCell: params => (
        <AdminCheckbox
          userId={params.row.user_id}
          userState={userState}
          setUserState={setUserState}
          handleRowChange={handleRowChange}
        />
      )
    }
  ];
};